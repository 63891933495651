import React, { FormEvent } from 'react';
import { Box, Grid } from 'theme-ui';
import { Paragraph, SubTitle, Title, themeConfig } from '@themed';
import { PageMeta, findFirstContentWithTabbedImageList, hasContentText } from '@types';
import { useAsyncAction, useTranslate } from '@modules';
import { ImageLoader } from '@core';
import { useDataPrivacyConfig } from 'src/store/DataPrivacyContext';
import { saveSubscription } from 'src/modules/networking/digital-experience/saveFormData';
import { gridLayout } from '../digital-experience/PDFForm.styles';
import { NewsLetterSignup } from '../NewsLetterSignup';
import { QuizLetterContent } from '../NewsLetterContent';
import { ErrorMessage } from '../chart-generator/auth';
import { quizletterStyles, styles } from './QuizForm.styles';

export interface PageMetaProps {
  content: PageMeta | undefined;
}

export const Form = ({ content }: PageMetaProps) => {
  const description = content?.content && hasContentText(content.content[1]) && content?.content?.[1].text;
  return (
    <Box sx={styles}>
      <Title>{content?.description}</Title>
      <Paragraph>{description}</Paragraph>
    </Box>
  );
};

const submittedFormStyles = {
  color: 'background',
};

export const QuizForm = ({ content }: PageMetaProps) => {
  const donwloadLinks = findFirstContentWithTabbedImageList(content?.content)?.tabbedImages;
  const teaserImage = donwloadLinks?.[0].image;
  const question = content?.content && hasContentText(content.content?.[0]) && content?.content?.[0]?.text;
  const __ = useTranslate();
  const [formData, setFormData] = React.useState<any>(undefined);
  const { resource, error } = useAsyncAction(() => saveSubscription(formData), [formData]);

  useDataPrivacyConfig({ path: '/data-privacy-quiz', text: __('navbar.dataprivacy-quiz') });

  const createQuiz = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const [firstName, lastName, email, privacy, answer] = e.target as any;
    setFormData({ answer: answer.value, firstName: firstName.value, lastName: lastName.value, email: email.value, privacy: privacy.checked });
  };

  return (
    <Grid id='quiz' sx={gridLayout()}>
      <Form content={content} />
      <NewsLetterSignup
        sx={{ ...quizletterStyles(true, themeConfig.colors.primary) }}
        Icon={
          teaserImage?.url ? <ImageLoader src={teaserImage.url} alt={__(teaserImage.alternativeText, true)} sx={{ my: 4 }} /> : <React.Fragment />
        }
        onSubmit={createQuiz}
      >
        {resource ? (
          <React.Fragment>
            <SubTitle sx={submittedFormStyles}>{__('quiz.form-submission.title')}</SubTitle>
            <Paragraph sx={submittedFormStyles}>{__('quiz.form-submission.message')}</Paragraph>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <QuizLetterContent id='quiz.newsletter' question={question} />
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </React.Fragment>
        )}
      </NewsLetterSignup>
    </Grid>
  );
};
