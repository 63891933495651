/* eslint-disable react/no-children-prop */
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Layout, StaticSEO } from '@core';
import { useStrapiPageContent } from 'src/content/useStrapiPageContent';
import { DigitalExperience, LocaleGuard } from '@composite';
import { QuizForm } from 'src/components/composite/quiz/QuizForm';

export default () => {
  const pageContent = useStrapiPageContent('quiz');

  return (
    <LocaleGuard enabledLocales={['de']} redirectTo='/de/finnoscore/quiz'>
      <Layout>
        <StaticSEO pageId='quiz' />
        <DigitalExperience pageContent={pageContent}>
          <QuizForm content={pageContent} />
        </DigitalExperience>
      </Layout>
    </LocaleGuard>
  );
};
